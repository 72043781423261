import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';



Swiper.use([Navigation, Pagination]);
const slider = new Swiper('.slider', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  navigation: {
    nextEl: '.next',
    prevEl: '.prev',
  },
  pagination: {
    el: '.swiper-pagination',
  },
  breakpoints: {
    // when window width is >= 320px
    992: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    1200: {
      slidesPerView: 3,
    },

  }
});

